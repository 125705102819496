import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

const LetterAvatar = props => {
  const { variant, children } = props;

  return (
    <Avatar variant={variant}>
      { children }
    </Avatar>
  );
};

LetterAvatar.defaultProps = {
  children: 'M',
  variant: 'circle',
};

LetterAvatar.propTypes = {
  children: PropTypes.string,
  variant: PropTypes.oneOf(['circle', 'square', 'rounded']),
};

export default LetterAvatar;