import { styled } from '@mui/system';

const StyledFooter = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: "100%",
  //position: "relative",
  //zIndex: 1302
}));

const StyledMarginRight = styled('div')(({theme}) => ({
  marginRight: "25px"
}));

const StyledMarginLeft = styled('div')(({theme}) => ({
  marginLeft: "25px"
}));

export { StyledFooter, StyledMarginRight, StyledMarginLeft }