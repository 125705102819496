import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import { HtmlHead } from '@components/HtmlHead';
import { Theme } from '@components/Theme';
import { ConfigProvider } from '@hooks/ConfigContext';
import { settings } from '@config/settings';
import { Router } from '@routers/Router';
import { init } from '@services/actionService';

init({apiUrl: settings.app.apiUrl});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: settings.app.rqQueriesRefetchOnWindowFocus,
      cacheTime: settings.app.rqQueriesCacheTime,
      staleTime: settings.app.rqQueriesStaleTime,
    },
  },
});

const App = () => {

  return (
    <React.StrictMode>
      <BrowserRouter>
        <HelmetProvider> 
          <ThemeProvider theme={Theme}>
            <QueryClientProvider client={queryClient}>
              <ConfigProvider config={settings}>
                <CssBaseline />
                <HtmlHead/>
                <Router />
              </ConfigProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </HelmetProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App