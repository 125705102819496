import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

const IconAvatar = (props) => {
  const { variant, children } = props;

  return (
    <Avatar variant={variant}>
      { children }
    </Avatar>
  );
};

IconAvatar.defaultProps = {
  variant: 'circle',
};

IconAvatar.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['circle', 'square', 'rounded']),
};

export default IconAvatar;