import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useHeadConfig } from '@hooks/ConfigContext';

const HtmlHead = () => {
  const { head } = useHeadConfig();
  const { title, description, author } = head;
  
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="author" content={author} />
    </Helmet>
  );
};

HtmlHead.defaultProps = {

};

HtmlHead.propTypes = {

};

export default HtmlHead