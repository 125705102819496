import React from 'react';

import Tabs from './TabsRouter';

const ScrollableTabsRouter = props => {
  const {...other } = props;

  return (
    <Tabs
      variant='scrollable'
      scrollButtons
      allowScrollButtonsMobile
      centered={false}
      {...other}
    />
  );
};

export default ScrollableTabsRouter;