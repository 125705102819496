import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types' 
import { Tab } from '@mui/material';

const TabLink = props => {
  const { to, label, icon, iconPosition, ...other } = props;

  return (
    <Tab 
      component={Link} 
      to={to} 
      label={label}
      icon={icon}
      iconPosition={iconPosition} 
      {...other}
    />
  )
};

TabLink.defaultProps = {
  to: '',
  label: '',
  icon: null,
  iconPosition: 'start'
};

TabLink.propTypes = {
  //to: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  icon: PropTypes.element,
  iconPosition: PropTypes.string
};  

export default TabLink;