import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types' 
import { nanoid } from 'nanoid';
import { Tabs as MuiTabs, Tab } from '@mui/material';

const Tabs = props => {
  const { navIndex, setNavIndex, routes } = props;

  const handleTabChange = (e, v) => setNavIndex(v);

  return (
      <MuiTabs 
        value={navIndex} 
        onChange={handleTabChange} 
        textColor="secondary"
        indicatorColor="secondary" 
        variant="fullWidth" 
        sx={{marginLeft: "auto", marginRight: "50px"}}
      >
        {
          routes.map(route => {
            const id = nanoid();
            return (
              <Tab 
                key={id} 
                component={Link} 
                to={route.link} 
                label={route.name} 
                sx={{
                  minWidth: 100,
                  color: "white",
                  opacity: 0.75,
                  "&:hover": {
                    opacity: 1
                  }
                }}
              />
            )
          })
        }
      </MuiTabs>
  );
};

Tabs.defaultProps = {
  navIndex: 0,
  setNavIndex: () => {},
  routes: []
};

Tabs.propTypes = {
  navIndex: PropTypes.number,
  setNavIndex: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};

export default Tabs