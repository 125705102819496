import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';

const DataGrid = props => {
  const { data, settings, pageSize, rowsPerPageOptions} = props;

  return (
    <MuiDataGrid 
      rows={data}
      columns={settings}
      pageSize={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
    />  
  );
};

DataGrid.defaultProps = {
  pageSize: 5,
  rowsPerPageOptions: [5]
};

DataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  settings: PropTypes.arrayOf(PropTypes.object),
  pageSize: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number)
}

export default DataGrid