import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Chip, Link } from '@mui/material';

const ChipLink = (props) => {
  const { title, label, url, icon, ...other} = props;
  return (
    <>
      <Tooltip title={title} arrow={true} placement="top-start">
        <Chip 
          label={label}
          component={Link} 
          href={url}
          variant="filled"
          color="primary"
          clickable
          icon={icon}
          size="small"
          {...other}
        />
      </Tooltip>
    </>
  ) 
};

ChipLink.defaultProps = {
  title: '',
  label: '',
  url: '',
  icon: null
};

ChipLink.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.node
};

export default ChipLink;