import httpService from './httpService';

let api = null;
const init = ({ apiUrl }) => {
  api = httpService({baseUrl: apiUrl});
};

const readAllRequest = async ({ url }) => {
  const { data } = await api.get(url);
  //console.log(`ReadAllRequest: ${url}`);
  //console.log(data);
  return data;
};

const readOneRequest = async ({ url, queryKey }) => {
  const [_key, id] = queryKey;
  const res = await api.get(`${url}/${id}`);
  return res?.data;
};

const createRequest = async ({ url, data }) => {
  const res = await api.post(url, data);
  return res?.data;
};

export {
  readAllRequest,
  readOneRequest,
  createRequest,
  init
}