import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Image from 'mui-image';

import Tabs from './Tabs';
import Drawer from './Drawer';
import Offset from './HeaderOffset';
import { useNavConfig, useResourceUrlsConfig } from '@hooks/ConfigContext';

const Header = props => {
  const theme = useTheme();
  const { nav } = useNavConfig();
  const { resourceUrl } = useResourceUrlsConfig();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const [ navIndex, setNavIndex ] = useState(0);
  const { appBarPosition } = props;

  return (
    <>
      <AppBar position={appBarPosition} sx={{zIndex: (theme) => theme.zIndex.modal + 1}}>
        <Toolbar disableGutters>
          <Button component={Link} to="/" onClick={() => setNavIndex(0)} disableRipple sx={{position: "relative"}}>
            <Image src={resourceUrl.logo} height="4em" width="100%" fit="cover" duration={3000} easing="ease-in" showLoading={true} errorIcon={true} shift={null} distance="100px" shiftDuration={900} bgColor="inherit"/>
          </Button>
            { 
              matches ?
                <Tabs navIndex={navIndex} setNavIndex={setNavIndex} routes={nav} />
                :
                <Drawer navIndex={navIndex} setNavIndex={setNavIndex} routes={nav} />
            }
        </Toolbar>
      </AppBar>
      <Offset/>
    </>
  );
};

Header.defaultProps = {
  appBarPosition: 'fixed',
  appBarColor: 'primary'
}

Header.propTypes = {
  appBarPosition: PropTypes.oneOf(['fixed', 'absolute', 'relative', 'static', 'sticky']),
  appBarColor: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary', 'transparent'])
}

export default Header