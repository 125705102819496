import React from 'react';
import { useQuery } from 'react-query';

import {
  Loading,
} from "@components/core";
import { readAllRequest} from '@services/actionService';

const Query = (props) => 
{
  const { queryKey, queryUrl, children, ...other} = props;
  const { isLoading, isError, data } = useQuery(
    queryKey,
    () => readAllRequest({ url: queryUrl })
  );

  return (
    <>
      {
        isLoading ? (
          <Loading />
        ) : isError ? (
          <div>Error</div>
        ) : (
          React.cloneElement(children, { data, ...other })
        )
      }
    </>
  );
}

export default Query;