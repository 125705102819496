import { createTheme } from '@mui/material/styles';

import buildTheme from '@config/settings';

const theme = createTheme({
  palette: {
    mode: buildTheme.mode,
    primary: {
      main: buildTheme.primary,
    },
    secondary: {
      main: buildTheme.secondary,
    }
  },
  typography: {
    tab: {
      textTransform: "none",
      fontSize: "1rem",
      fontWeight: 700
    },
    menu: {
      textTransform: "none",
      fontSize: "2rem",
      fontWeight: 100
    }
  }
});

export default theme;