import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';

const IconDecorator = (props) => {
  const { IconComponent } = props;

  return (
    <>
      { React.cloneElement(IconComponent, {	fontSize: "default", color: "primary" }) }
    </>
  );
} 

const IconLink = (props) => {
  const { title, link, Icon, ...other} = props;

  return (
    <>
      <Tooltip title={title} arrow={true} {...other}> 
        <IconButton component={link} sx={{marginRight: '15px', padding: '1px'}}>
          <IconDecorator IconComponent={Icon} />
        </IconButton>        
      </Tooltip>
    </>      
  );
};

IconLink.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  Icon: PropTypes.element.isRequired,
};

export default IconLink;