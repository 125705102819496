const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
};

const tabIndexProps = (index) => {
  return {
    'aria-labelledby': `scrollable-auto-tabpanel-${index}`,
  };
};

export { a11yProps, tabIndexProps };