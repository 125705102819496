import {useState, useEffect} from 'react';

const setLocalStorageItem = (key, value) => {
  if(value && typeof(value) === 'string') {
    localStorage.setItem(key, value);
  } else {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

const getLocalStorageItem = key => {
  const data = localStorage.getItem(key);
  if(!data) {
    return "";
  }
  try {
    return JSON.parse(data);
  } catch(e) {
    return data;
  }
};

const removeLocalStorageItem = key => localStorage.removeItem(key);

const clearLocalStorage = () => localStorage.clear();

const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    getLocalStorageItem(key) || initialValue;
  });

  useEffect(() => {
    setLocalStorageItem(key, storedValue);
  }, [storedValue, key]);

  return [storedValue, setStoredValue];
};

export { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem, clearLocalStorage, useLocalStorage }
