import React from 'react';
import { useQuery } from 'react-query';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box'

//import { GridContainerV2 as GridContainer, GridItemV2 as GridItem } from '@components/Grid';
//import { Carousel } from '@components/Carousel';
//import { ListNotices } from '@components/List';
//import { Table } from '@components/Table';
//import { SimpleCard } from '@components/Card';
//import { Loading } from '@components/Loading';

import { 
  GridContainer, 
  GridItem,
  Carousel,
  ListNotices,
  Table,
  SimpleCard,
  Loading
} from '@components/core';
import { readAllRequest} from '@services/actionService';

const eventsColumns = [
  {
    Header: 'Date',
    accessor: 'date',
    disableSortBy: true
  },
  {
    Header: 'Fixture',
    accessor: 'event',
    disableSortBy: true
  },
  {
    Header: 'Venue',
    accessor: 'venue',
    disableSortBy: true
  },
];

const resultsColumns = [
  {
    Header: 'Competition',
    accessor: 'compname',
    disableSortBy: true
  },
  {
    Header: 'Date',
    accessor: 'compdate',
    disableSortBy: true
  },
  {
    Header: 'Venue',
    accessor: 'compvenue',
    disableSortBy: true
  }
];

const Home = (props) => {
  const { isLoading:  isLoadingEvents, isError: isErrorEvents, data: dataEvents } = useQuery(
    ['events', 'events'],
    () => readAllRequest({url: 'events/events'})
  );

  const { isLoading: isLoadingResults, isError: isErrorResults, data: dataResults } = useQuery(
    ['results', '2024'],
    () => readAllRequest({url: 'results/2024'})
  );

  const { isLoading: isLoadingNotices, isError: isErrorNotices, data: dataNotices } = useQuery(
    ['notices', 'notices'],
    () => readAllRequest({url: 'notices'})
  );
  
  const { isLoading: isLoadingCarousel, isError: isErrorCarousel, data: dataCarousel } = useQuery(
    ['carousel', 'carousel'],
    () => readAllRequest({url: 'carousel'})
  );

  return (
    <>
      <Box sx={{ flexGrow: 1}}>
        <GridContainer spacing={2} sx={{m:2}}>
          <GridItem xs={12} md={9}> 
            <GridContainer>
              <GridItem xs={12}>
                {
                  isLoadingCarousel ? (
                    <Loading />
                  ) : isErrorCarousel ? (
                    <p>Error</p>
                  ) : (
                    <Carousel carouselItems={dataCarousel}/>
                  )
                }
                <Divider variant="middle" sx={{margin: 2}}/>
              </GridItem>
              <GridItem xs={12} md={6}>
                <SimpleCard title="Next Events" sx={{ height: '100%' }}>
                  {
                    isLoadingEvents ? (
                      <Loading/>
                    ) : isErrorEvents ? (
                      <p>Error</p>
                    ) : (
                      <Table headings={eventsColumns} dataRows={dataEvents} enableFilter={false} enablePagination={false}/>
                    )
                  } 
                </SimpleCard>
              </GridItem>
              <GridItem xs={12} md={6}>
                <SimpleCard title="Latest Results" sx={{ height: '100%' }}>
                  {
                    isLoadingResults ? (
                      <Loading/>
                    ) : isErrorResults ? (
                      <p>Error</p>
                    ) : (
                      <Table headings={resultsColumns} dataRows={dataResults} enableFilter={false} enablePagination={false}/>
                    )
                  } 
                </SimpleCard>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} md={3}>
            <SimpleCard title="Notices" sx={{ height: '100%', marginTop: '8px' }}>
              { 
                isLoadingNotices ? (
                  <Loading/>
                ) : isErrorNotices ? (
                  <p>Error</p>
                ) : (                                    
                  <ListNotices notices={dataNotices}/>
                )
              }
            </SimpleCard>              
          </GridItem>
        </GridContainer>      
      </Box>
    </>
  );
};

export default Home;