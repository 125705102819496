import React from 'react';

import { routerConfig } from '@config/settings';


const Router = props => {
  const routes = routerConfig();

  return (
    <>
      {routes}
    </>
  );
};

export { Router }