import React, { useState } from 'react';
import { nanoid } from 'nanoid';

import { StyledFooter, StyledMarginRight, StyledMarginLeft } from './StyledFooter';
import { GridContainer, GridItem } from '@components/Grid';
import { Copyright } from '@components/Copyright';
import { useSocialsConfig, useResourceUrlsConfig } from '@hooks/ConfigContext';

const Footer = props => {
  const { socials } = useSocialsConfig();
  const { resourceUrl } = useResourceUrlsConfig();

  return (
    <StyledFooter>
      <GridContainer>
        <GridContainer item justifyContent="flex-start" alignItems="center" rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3}}>
          <GridItem sx={{marginLeft: "50px"}}>
            <StyledMarginLeft/>
            <Copyright linkText={resourceUrl.linkText} linkUrl={resourceUrl.linkUrl} sx={{marginLeft: "100px"}}/>
          </GridItem>
        </GridContainer>
        <GridContainer item justifyContent="flex-end" rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3}}>
          { 
            socials.map(social => {
              const id = nanoid();
              return (
                <GridItem key={id} component={'a'} href={social.link} rel="noopener noreferrer" target="_blank" sx={{margin: "0.5em"}}>
                  { 
                    social.icon
                  }
                </GridItem>
              )
            })
          }
          <StyledMarginRight/>
        </GridContainer>  
      </GridContainer>
    </StyledFooter>
  )
};

export default Footer
