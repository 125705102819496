import React, { useState } from 'react';
import PropTypes from 'prop-types' 
import { nanoid } from 'nanoid';
import { Tabs } from '@mui/material';

import TabLink from './TabLink';

const TabsRouter = props => {
  const [ tabIndex, setTabIndex ] = useState(0);
  const { 
    tabHeadings,
    children,
    variant,
    orientation,
    scrollButtons,
    allowScrollButtonsMobile,
    centered,
    textColor,
    selectionFollowsFocus,
    indicatorColor,
    iconPosition,
    ...other
  } = props;

  const handleTabChange = (e, v) => setTabIndex(v);

  return (
      <Tabs 
        value={tabIndex} 
        onChange={handleTabChange} 
        variant={variant}
        orientation={orientation}
        scrollButtons={scrollButtons}
        allowScrollButtonsMobile={allowScrollButtonsMobile}
        centered={centered}
        textColor={textColor}
        selectionFollowsFocus={selectionFollowsFocus}
        indicatorColor={indicatorColor}
        aria-label="scrollable tabs"
        {...other}
      >
        {
          tabHeadings.map(route => {
            const id = nanoid();
            return (
              <TabLink
                key={id} 
                to={route.link} 
                label={route.label} 
              />
            )
          })
        }
      </Tabs>
  );
};

TabsRouter.defaultProps = {
};

TabsRouter.propTypes = {
};

export default TabsRouter