const appSettings = {
  rqQueriesRetry: 2,
  rqQueriesStaleTime: 1000 * 300,// 300 seconds
  rqQueriesCacheTime: 1000 * 300, //300 seconds
  rqQueriesRefetchOnMount: "always",
  rqQueriesRefetchOnWindowFocus: "always",
  rqQueriesRefetchOnReconnect: "always",
  rqQueriesRefetchInterval: 1000 * 300, //30 seconds
  rqQueriesRefetchIntervalInBackground: false,
  rqQueriesSuspense: false,
  rqMutationsRetry: 2,
}

export { appSettings }