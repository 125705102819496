import React, { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';

import { PortalLayout, EmptyLayout } from '@components/Layout';
import { NotFound } from '@components/Error';
import { Loadable } from '@components/Loading';
import { Home } from '@views/Home';

const About = Loadable(lazy(()=> import('@views/About').then(module=>({default:module.About}))));
const Events = Loadable(lazy(()=> import('@views/Events').then(module=>({default:module.Events}))));
const Results = Loadable(lazy(()=> import('@views/Results').then(module=>({default:module.Results}))));
const Records = Loadable(lazy(()=> import('@views/Records').then(module=>({default:module.Records}))));
//const News = Loadable(lazy(()=> import('@views/News').then(module=>({default:module.News}))));

const navConfig = [
  { name: 'Home', link: '/'},
  { name: 'Events', link: '/events'},  
  { name: 'Results', link: '/results'},
//  { name: 'News', link: '/news'},
  { name: 'Records', link: '/records'},
  { name: 'About', link: '/about'},
]; 

const routerConfig = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <PortalLayout />,
      children: [
        {
          index: true,
          element: <Home />
        },
        {
          path: 'events',
          element: <Events />
        },
        {
          path: 'results/*',
          element: <Results />,
        },
  //      {
  //        path: 'news',
  //        element: <News />
  //      },
        {
          path: 'records',
          element: <Records />
        }, 
        { 
          path: 'about', 
          element: <About />, 
        },
//        {
//          path: 'live/*',
//          element: <Live />,
//        },
        {
          path: '404',
          element: <NotFound />
        },
        {
          path: '*',
          element: <Navigate to="/404"/>
        }

      ]
    },
    { 
        path: '*',
        element: <Navigate to="/404" replace/>
    }
  ]);
  return routes;
}

export default navConfig
export { routerConfig } 