import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

const ImageAvatar = props => {
  const { variant, alt, src } = props;

  return (
    <Avatar variant={variant} alt={alt} src={src}/>
  );
};

ImageAvatar.defaultProps = {
  alt: 'M',
  variant: 'circle',
};

ImageAvatar.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  variant: PropTypes.oneOf(['circle', 'square', 'rounded']),
};

export default ImageAvatar
