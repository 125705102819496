import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import SearchIcon from '@mui/icons-material/Search';
import {
  InputAdornment,
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableFooter,
  TableBody,
  TableRow,
  TableColumn,
  TablePagination,
  TableSortLabel,
  TableCell,
  TextField,
  useMediaQuery
} from '@mui/material';

const Table = (props) => {
  const { headings, dataRows, dataDep, enableFilter, enablePagination, rowsPerPageOptions, colHideSize } = props;
  const matches = useMediaQuery((theme) => theme.breakpoints.down(colHideSize));
  
  const rowsPerPage = [...rowsPerPageOptions, { label: 'All', value: dataRows.length}];
  const initPageSize = rowsPerPage[0];

  const columns = useMemo(() => headings, []);
  const data = useMemo(() => dataRows, [dataDep]);

  const table = useTable(
    {
      columns, 
      data,
      initialState: { pageIndex: 0, pageSize: initPageSize}
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    prepareRow, 
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount, 
    gotoPage,
    nextPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter }
  } = table;

  let hide = false;

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  }
  
  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value));
  }

  const handleChangeGlobalFilter = (event) => {
    setGlobalFilter(event.target.value);
  };

 // ;

  return (
    <>
      <TableContainer sx={{margin: "1px"}}>
        {
          enableFilter && 
          <TextField 
            id="input-with-icon-textfield"
            label={globalFilter || ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
            onChange={handleChangeGlobalFilter}
            sx={{my: 2}}
          />
        }
        <MuiTable size='small' {...getTableProps()}>
          <TableHead>
            {
              headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {
                    headerGroup.headers.map(column => {
                      const {title} = column.getSortByToggleProps();
                      hide = matches && column.colHide === colHideSize;
                      return (
                        !hide &&
                        <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>                            
                          {  
                            title ? 
                              <TableSortLabel 
                                active={column.isSorted}
                                // react-table has a unsorted state which is not treated here
                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                              > 
                                { column.render('Header')} 
                              </TableSortLabel>
                            :
                            column.render('Header')
                          }
                        </TableCell>
                      )
                    })
                  }
                </TableRow>
              ))
            }
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {
              page.map((row, i) => {
                prepareRow(row)

                return (
                  <TableRow hover={true} {...row.getRowProps()}>
                    {
                      row.cells.map((cell, i) => {
                        hide = matches && cell.column.colHide === colHideSize;
                        return (
                          !hide &&
                          <TableCell {...cell.getCellProps()}>
                            {
                              cell.render('Cell')
                            }
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                )
              })
            }
          </TableBody>
          <TableFooter>
            <TableRow sx={{justifyContent: "flex-start"}}>
              {
                enablePagination && <TablePagination
                  rowsPerPageOptions={rowsPerPage}
                  count={data.length}
                  rowsPerPage={pageSize}
                  page={pageIndex}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true}
                />
              }
            </TableRow>
          </TableFooter>
        </MuiTable>
      </TableContainer>
    </>
  )
};

Table.defaultProps = {
  enableFilter: true,
  enablePagination: true,
  rowsPerPageOptions: [ 5, 10, 20 ],
  colHideSize: 'sm',
};

Table.propTypes = {
  enableFilter: PropTypes.bool,
  enablePagination: PropTypes.bool,
  rowsPerPageOptions: PropTypes.array,
  colHideSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
};

export default Table
