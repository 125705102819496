import React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Header } from '@components/Header';
import { Footer } from '@components/Footer';

const PortalLayout = props => {

  return (
    <>
      <Header/>
      <Outlet/>
      <Footer/>
    </>
  );
};

PortalLayout.defaultProps = {

};

PortalLayout.propTypes = {

};

export default PortalLayout