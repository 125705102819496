import axios from 'axios';
import { getLocalStorageItem } from '@utils/storageUtils';

const httpService = (options) => {
  const { baseUrl } = options;
  console.log(baseUrl);
  const api = axios.create({
    baseURL: baseUrl,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
  });

  api.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  api.interceptors.request.use(
    config => {
      const token = getLocalStorageItem('JWT_ACCESS_TOKEN');
      if(token) {
        config.headers.authorization = `Bearer ${token}`;	
      }
      return config;
    },
    error => Promise.reject(error)
  );

  api.interceptors.response.use(
    res => res,
    error => {
      if(error.response.status === 401) {
        // redirect to login
      }
      if(error.response.status === 404) {
        //redirect to Not Found
      }
      if(error.response.status === 500) {
        //redirect to
      }
      return Promise.reject(error);
    }
  );

  return api;
};

export default httpService 
