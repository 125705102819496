import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import  {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemIcon,
  ListItemButton,
  Divider
} from '@mui/material';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import ArticleIcon from '@mui/icons-material/Article';

import { IconAvatar } from '@components/Avatar';
import { InnerHTML } from '@components/InnerHTML';

const noticeIcon = {
  announcement : <AnnouncementIcon color="primary"/>,
  article : <ArticleIcon color="primary"/>,
  result : <FormatListNumberedIcon color="primary"/>
};

const ListNotices = (props) => {
  const { notices } = props;

  return (
    <>
      <List dense>
        {
          notices.map( (item, i) => {
            //const [key] = useState(nanoid());
            const { title, description, type } = item;
            const icon = noticeIcon[type];
            return ( 
              <>
                <ListItem key={i}>
                  <ListItemAvatar>
                    <IconAvatar>
                      { icon }
                    </IconAvatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={title}
                    secondary={<InnerHTML html={description}/>}
                    secondaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                  />
                </ListItem>
                { i !== notices.length - 1 && <Divider key={i} variant="inset" component="li" /> }
              </>
            )
          })
        }
      </List>
    </>
  );
}

ListNotices.defaultProps = {};

ListNotices.propTypes = {
  notices: PropTypes.arrayOf(PropTypes.object)
};

export default ListNotices;