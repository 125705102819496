import React from 'react';
import DOMPurify from 'dompurify';

const InnerHTML = (props) => {
  const { html } = props;
  const sanitizedHtml = DOMPurify.sanitize(html);

  return (
    <span dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
  );
}

export default InnerHTML;