import React from 'react';
import PropTypes from 'prop-types';

const DashboardLayout = (props) => {
  const { children, ...rest } = props;

  return (
    <>
      <div>Header Goes Here</div>
      <div>
        { children }
      </div>
      <div>Footer Goes Here</div>
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout