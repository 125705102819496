import React from 'react';
import PropTypes from 'prop-types';
import MuiCarousel from 'react-material-ui-carousel';

import { CardMedia } from '@components/Card'; 

const CarouselItem = (props) => {
  const { item } = props;
  const { image, name, description, subheader } = item;

  return (
    <>
      { React.cloneElement(<CardMedia/>, { title: name, subHeader: subheader,  image: image, description: description }) }
    </>
  );
};

const Carousel = (props) => {
  const { carouselItems } = props;

  return (
    <>
      <MuiCarousel
        interval={4000}
        animation="fade"
        navButtonsAlwaysVisible={true}
      >
        {
          carouselItems.map( (item, i) => <CarouselItem key={i} item={item} /> )
        }
      </MuiCarousel>
    
    </>
  );
}
// TODO Add PropTypes
Carousel.defaultProps = {

};

Carousel.propTypes = {
  carouselItems: PropTypes.arrayOf(PropTypes.object)
};

export default Carousel