import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';

const GridItem = (props) => {
  const { children, ...rest } = props;
  
  return (
    <Grid 
      item 
      {...rest} 
    >
      {children}
    </Grid>
  );
};

GridItem.defaultProps = {
};  

GridItem.propTypes = {
  children: PropTypes.node,
};

export default GridItem