import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Unstable_Grid2';

const GridContainer = (props) => {
  const { children, ...rest } = props;
  
  return (
    <Grid container {...rest} >
      {children}
    </Grid>
  );
};

GridContainer.propTypes = {
  children: PropTypes.node,
};

export default GridContainer