import React from 'react';
import PropTypes from 'prop-types';
import { 
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActionArea,
  Typography
} from '@mui/material';

const CardMediaSummary = (props) => {
  const { title, subHeader, image, description } = props;

  return (
    <>
      <Card raised>
        <CardActionArea>
          <CardMedia
            component="img"
            image={image}
            title={title}
            alt={title}
            sx={{
              height: '500px',
              width: '100%',
              objectFit: 'contain',
              transform: 'scale(1)',
              transition: 'transform .5 ease-in-out',
              '&:hover': {
                filter: 'brightness(120%)',
                transform: 'scale(1.05)',
                transition: 'transform .5s ease-in-out'
              }
            }}
          />
          <CardHeader
            title={title} 
            subheader={subHeader}
            sx={{
              textAlign: 'center'
            }}
          />
          <CardContent
            sx={{
              textAlign: 'center',
              '&:hover': {
                opacity: '1.1'
              }
            }}
          >
            <Typography>
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

CardMediaSummary.defaultProps = {

};

CardMediaSummary.propTypes = {
  title: PropTypes.string,
  subHeader: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string
};

export default CardMediaSummary;