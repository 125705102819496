import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = (props) => {
  const { children, tabIndex, index, id, ...rest } = props;

  return (
    <>
      <div
        id={`scrollable-auto-tabpanel-${id}`}
        hidden={tabIndex !== index}        
        role="tabpanel"
        aria-labelledby={`scrollable-auto-tab-${index}`}
        style={{padding: '8px'}}
        {...rest}
      >
        {children}
      </div>
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  tabIndex: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
};

export default TabPanel