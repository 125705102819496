import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types' 
import { nanoid } from 'nanoid';
import { SwipeableDrawer, List, ListItemButton, ListItemText, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import Offset from './HeaderOffset';

const Drawer = props => {
  const { navIndex, setNavIndex, routes } = props;
  const [ open, setOpen ] = useState(false);
  const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawerHandler = () => setOpen(!open);
  const openDrawerHandler = () => setOpen(true);
  const closeDrawerHandler = () => setOpen(true);

  const clickHandler = (event, index) => {
    setOpen(false); 
    setNavIndex(index);
  };

  return (
    <>
      <SwipeableDrawer 
        disableBackdropTransition={!iOS} 
        disableDiscovery={iOS}
        open={open}
        onClose={closeDrawerHandler}
        onOpen={openDrawerHandler}
        sx={{
          bgcolor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <Offset sx={{marginBottom: "1.25em"}}/>
        <List disablePadding
          sx={{
            bgcolor: (theme) => theme.palette.primary.main,
            // color: (theme) => theme.palette.primary.main,
          }}
        >
          {
            routes.map((route, index) => {
              const id = nanoid();
              return(
                <ListItemButton
                  key={id}
                  component={Link}
                  to={route.link}
                  selected={navIndex===index}
                  onClick={(event) => clickHandler(event, index)}
                  sx={{
                    color: "white",
                    opacity: 0.7,
                    "&:hover": {
                      opacity: 1
                    }
                  }}
                >
                  <ListItemText>
                    {route.name}
                  </ListItemText>
                </ListItemButton>
              )
            })
          }
        </List>
      </SwipeableDrawer>
      <IconButton 
        onClick={toggleDrawerHandler} 
        disableRipple 
        sx={{ 
          marginLeft: "auto", 
          "&:hover": {
            backgroundColor: "transparent"
          }
        }}
      > 
        <MenuIcon sx={{
            height: "1.25em",
            width: "1.25em",
            color: "white"
          }}
        />
      </IconButton>
    </>
  );
};

Drawer.defaultProps = {
  navIndex: 0,
  setNavIndex: () => {},
  routes: []
};

Drawer.propTypes = {
  navIndex: PropTypes.number,
  setNavIndex: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
};
export default Drawer