import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import {
  Accordion as MuiAccordion, 
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Accordion = (props) => {
  const { summary, children, ...other } = props;
  const [key] = useState(nanoid());

  return (
    <>
      <MuiAccordion {...other}>
        <AccordionSummary 
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel-content-${key}`}
          id={`panel-${key}`}
        >
          { summary }
        </AccordionSummary>
        <AccordionDetails>
          { children }
        </AccordionDetails>
      </MuiAccordion>
    </>
  );
};

Accordion.defaultProps = {
}

Accordion.propTypes = {
  summary: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Accordion;