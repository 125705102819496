import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@mui/material';

const Loading = props => {
  const { animation, variant } = props;

  return (
    <>
      <Skeleton animation={animation} variant={variant} width="80%" height={20} sx={{mb:2}}/>
      <Skeleton animation={animation} variant={variant} width="80%" height={20} sx={{mb:2}}/>
      <Skeleton animation={animation} variant={variant} width="80%" height={20} sx={{mb:2}}/>
    </>
  );
};

PropTypes.defaultProps = {
  animation: 'wave',
  variant: 'rectangular'
};

PropTypes.propTypes = {
  animation: PropTypes.oneOf['wave', 'pulse'],
  variant: PropTypes.oneOf['rectangular', 'circle']
};

export default Loading;