import React from "react";
import { Card, CardHeader, CardContent } from "@mui/material";

const SimpleCard = (props) => {
  const {title, subHeader, children, ...other} = props;
  return (
    <>
      <Card raised {...other}>
        <CardHeader
          title={title}
          subheader={subHeader}
          sx={{
            textAlign: 'center',
            bgcolor: 'primary.main',
            color: "white",
          }}
        />
        <CardContent
          sx={{
            p:1
          }}
        >
          {children}
        </CardContent>
      </Card>
    </>
  );
};

export default SimpleCard;