import React from 'react';
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';

import navConfig from './router';
import logo from './assets/munster.png';
import { baseAppSettings } from '../../base';
import urlSettings from '@environment';

const apiUrl = urlSettings.apiUrl;

const appSettings = Object.assign({}, baseAppSettings, {
  apiUrl: apiUrl,
  rqQueriesCacheTime: 1000 * 60 * 30,
  rqQueriesStaleTime: 1000 * 60 * 10,
  rqQueriesRefetchOnWindowFocus: false,
});

const socialsConfig = [
  { 
    name: 'Facebook', 
    link: 'http://facebook.com/MunsterAthletics', 
    icon: <Facebook fontSize="large" color="secondary"/>
  },
  { 
    name: 'Twitter', 
    link: 'http://twitter.com/MunsterAthleti1',
    icon: <Twitter fontSize="large" color="secondary"/>
  }
];

const headConfig = {
  title: 'Munster Athletics',
  description: 'Munster Athletics Portal',
  author: 'Mike Godley <mgodley@munsteratletics.com>',
};

const linksConfig = {
  linkText: 'Munster Athletics',
  linkUrl: 'http://munsterathletics.com',
  logo: logo,
  apiUrl: apiUrl,
};

const settings = {
  app: appSettings,
  nav: navConfig,
  head: headConfig,
  links: linksConfig,
  socials: socialsConfig
};

export { settings }