import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import { 
  Tabs as MuiTabs, 
  Tab
} from '@mui/material';

import { a11yProps, tabIndexProps } from './TabProps';
import TabPanel from './TabPanel';

const Tabs = props => {
  const [ tabIndex, setTabIndex ] = useState(0);
  const { 
    tabHeadings,
    children,
    variant,
    orientation,
    scrollButtons,
    allowScrollButtonsMobile,
    centered,
    textColor,
    selectionFollowsFocus,
    indicatorColor,
    iconPosition,
    ...other
  } = props;


  const handleTabChange = (event, value) => {
    setTabIndex(value);
  }
  
  const panels = (
    <>
      {
        children && children.map((panel, index) => {
          //const [id] = useState(nanoid());
          const id = nanoid();
          return (
            <TabPanel key={id} tabIndex={tabIndex} index={index} {...a11yProps(index)} {...tabIndexProps(index)}>
              { panel }
            </TabPanel>
          );
        })
      }
    </>
  );

  return (
    <>
      <MuiTabs
        value={tabIndex}
        onChange={handleTabChange}
        variant={variant}
        orientation={orientation}
        scrollButtons={scrollButtons}
        allowScrollButtonsMobile={allowScrollButtonsMobile}
        centered={centered}
        textColor={textColor}
        selectionFollowsFocus={selectionFollowsFocus}
        indicatorColor={indicatorColor}
        aria-label="scrollable tabs"
        {...other}
      >
        {
          tabHeadings.map((heading, index) => {
            const id = nanoid();
            return (
              <Tab 
                key={id}
                label={heading.label}
                icon={heading.icon}
                value={index}
                iconPosition={iconPosition}
                {...a11yProps(index)}
              />
            )
          })
        }
      </MuiTabs>
      { panels }
    </>
  );
};

Tabs.defaultProps = {
  variant: 'fullWidth',
  orientation: 'horizontal',
  scrollButtons: 'auto',
  allowScrollButtonsMobile: false,
  centered: true,
  indicatorColor: 'secondary',
  textColor: 'primary',
  selectionFollowsFocus: true,
  iconPosition: 'top',
};

Tabs.propTypes = {
  variant: PropTypes.oneOf (['fullWidth', 'scrollable', 'standard']),
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  scrollButtons: PropTypes.oneOf(['auto', false, true]),
  allowScrollButtonsMobile: PropTypes.bool,
  textColor: PropTypes.oneOf(['inherit', 'primary', 'secondary']),
  indicatorColor: PropTypes.oneOf(['primary', 'secondary']),
  centered: PropTypes.bool,
  selectionFollowsFocus: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['bottom', 'top', 'start', 'end']),
};

export default Tabs