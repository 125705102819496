import React from 'react';
import PropTypes from 'prop-types';

import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import WebIcon from '@mui/icons-material/Web';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton
} from '@mui/material';

const Contact = (props) => {
  const { contact } = props;

  return (
    <>
          <List>
            <ListItem>
              <ListItemIcon>
                <PersonIcon color="primary"/>
              </ListItemIcon>
              <ListItemText primary="Name" secondary={contact.contactname} />
            </ListItem>
            {
              contact.contactaddress && (
                <ListItem>
                  <ListItemIcon>
                    <HomeIcon color="primary"/>
                  </ListItemIcon>
                  <ListItemText primary="Address" secondary={contact.contactaddress} />
               </ListItem>
              )
            }
            {
              contact.contactphone && (
                <ListItem>
                  <ListItemIcon>
                    <PhoneIcon color="primary"/>
                  </ListItemIcon>
                  <ListItemText primary="Phone" secondary={contact.contactphone} />
                </ListItem>
              )
            }
            {
              contact.email && (
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon color="primary"/>
                  </ListItemIcon>
                  <ListItemButton component="a" href={`mailto:${contact.email.emailurl}`} disableGutters>
                    <ListItemText primary="Email" secondary={contact.email.emailtitle} />
                  </ListItemButton>
                </ListItem>
              )
            }  
            {
              contact.web && (            
                <ListItem>
                  <ListItemIcon>
                    <WebIcon color="primary"/>
                  </ListItemIcon>
                  <ListItemButton component="a" href={contact.web.weburl} disableGutters>
                    <ListItemText primary="Web" secondary={contact.web.webtitle} />
                  </ListItemButton>
                </ListItem>
              )
            }
            {
              contact.facebook && (
                <ListItem>
                  <ListItemIcon>
                    <FacebookIcon color="primary"/>
                  </ListItemIcon>
                  <ListItemButton component="a" href={contact.facebook.facebookurl} disableGutters>
                    <ListItemText primary="Facebook" secondary={contact.facebook.facebooktitle} />
                  </ListItemButton>
                </ListItem>
              )
            }
            {
              contact.instagram && (
                <ListItem>
                  <ListItemIcon>
                    <InstagramIcon color="primary"/>
                  </ListItemIcon>
                  <ListItemButton component="a" href={contact.instagram.instagramurl} disableGutters>
                    <ListItemText primary="Instagram" secondary={contact.instagram.instagramtitle} />
                  </ListItemButton>
                </ListItem>
              )
            }
            {
              contact.twitter && (
                <ListItem>
                  <ListItemIcon>
                  <TwitterIcon color="primary"/>
                  </ListItemIcon>
                  <ListItemButton component="a" href={contact.twitter.twitterurl} disableGutters>
                    <ListItemText primary="Twitter" secondary={contact.twitter.twittertitle} />
                  </ListItemButton>
                </ListItem>
              )
            }
          </List>

    </>
  );
}

export default Contact;
