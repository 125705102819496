import React, { useState } from 'react';

const ConfigContext = React.createContext(null);

const ConfigProvider = ({ config, children }) => {
  const [currentConfig, setCurrentConfig] = useState(config);

  return (
    <ConfigContext.Provider value={{ currentConfig, setCurrentConfig }} >
      { children }
    </ConfigContext.Provider>
  );
}

const useConfig = () => React.useContext(ConfigContext);

const useNavConfig = () => {
  const { currentConfig } = useConfig();
  const nav = currentConfig.nav;
  return { nav: nav };
};

const useHeadConfig = () => {
  const { currentConfig } = useConfig();
  const head = currentConfig.head;
  return { head: head };
};

const useSocialsConfig = () => {
  const { currentConfig } = useConfig();
  const socials = currentConfig.socials;
  return { socials: socials };
}

const useResourceUrlsConfig = () =>{
  const { currentConfig } = useConfig();
  const resourceUrl = currentConfig.links;
  return { resourceUrl: resourceUrl }
}

export { 
  ConfigProvider, 
  useNavConfig, 
  useHeadConfig, 
  useSocialsConfig, 
  useResourceUrlsConfig 
}