import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';

const NotFound = props => {
  const { variantTitle, variantBody, iconFontSize, iconColor } = props;

  return (
    <>
      <ErrorIcon fontSize={iconFontSize} color={iconColor}/>
      <Typography variant={variantTitle}>
        404 - Resource Not Found
      </Typography>
      <Typography variant={variantBody}>
        I'm sorry, the resource you were looking for cannot be found
      </Typography>
    </>
  );
};

NotFound.defaultProps = {
  variantTitle: 'h5',
  variantBody: 'h6',
  iconFontSize: 'large',
  iconColor: 'error',
};

NotFound.propTypes = {
  variantTitle: PropTypes.oneOf(['body1', 'body2', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  variantBody: PropTypes.oneOf(['body1', 'body2', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  iconFontSize: PropTypes.oneOf(['inherit', 'large', 'medium', 'small']),
  iconColor: PropTypes.oneOf(['inherit', 'action', 'disabled', 'primary', 'secondary', 'error', 'info', 'success', 'warning']),
};

export default NotFound