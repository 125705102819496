import React from 'react';
import PropTypes from 'prop-types';
import { 
  Card,
  CardContent,
  CardHeader,
  CardMedia as MuiCardMedia,
  Typography
} from '@mui/material';

const CardMedia = (props) => {
  const { title, subHeader, image, description } = props;
  
  return (
    <>
      <Card raised>
        <CardHeader 
          title={title} 
          subheader={subHeader}
          sx={{
            textAlign: 'center',
            bgcolor: 'primary.main',
            color: "white",
          }}
        />
        <MuiCardMedia
          component="img"
          image={image}
          title={title}
          alt={title}
          sx={{
            p:2,
            height: [200, 300, 400, 400, 400],
            width: '100%',
            objectFit: 'contain',
            transform: 'scale(1)',
            transition: 'transform .5 ease-in-out',
            '&:hover': {
              filter: 'brightness(120%)',
              transform: 'scale(1.05)',
              transition: 'transform .5s ease-in-out'
            }
          }}
        />
        <CardContent
          sx={{
            p: 1,
            textAlign: 'center',
            '&:hover': {
              opacity: '1.1'
            }
          }}
        >
          <Typography>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

CardMedia.defaultProps = {

};

CardMedia.propTypes = {
  title: PropTypes.string,
  subHeader: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string
};

export default CardMedia