import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from 'react-table';
import { FaSearch } from 'react-icons/fa';
import SearchIcon from '@mui/icons-material/Search';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import CategoryIcon from '@mui/icons-material';
import {
  Avatar,
  Chip,
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableFooter,
  TableBody,
  TableRow,
  TableColumn,
  TablePagination,
  TableSortLabel,
  TableCell,
} from '@mui/material';

const Profile = (props) => {
  const { m, profile } = props;
  return (
    <>
      <Chip 
        label={profile}
        component="a"
        href="#"
        variant="filled"
        color="success"
        clickable
        avatar={<Avatar>{profile}</Avatar>}
        sx={{m: 1}}
      />
    </>
  ) 
}

const Category = props => {
  const { m, category } = props;
  return (
    <>
      <Chip 
        label={category}
        component="a"
        href="#"
        variant="filled"
        color="warning"
        clickable
        avatar={<Avatar>{category}</Avatar>}
        sx={{m: 1}}
      />
    </>
  )
}

// TODO: RQ integration
// TODO: Pass data and columns as props
const Table = (props) => {
  //const { columns, data } = props;
  const { enableFilter, enablePagination } = props;
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        disableSortBy: true
      },
      {
        Header: 'Age',
        accessor: 'age',
        sortType: 'basic'
      },
      {
        Header: 'Status',
        accessor: 'status',
        sortType: 'basic',
        Cell: props => {
          return (props.cell.value);
        }
      },
      {
        Header: 'Role',
        accessor: 'role',
        sortType: 'basic',
        Cell: ({cell: {value}}) => value + " Role"
      },
      {
        Header: 'Links',
        accessor: 'links',
        disableSortBy: true,
        Cell: props => {
          const { value } = props;
          if(!value) return null;
          const { profile, category } = value;
          return (
            <>
              { profile ? <Profile m={1} profile={profile}/> : null }
              { category ? <Category m={1} category={category}  /> : null }
            </>  
          )
        }
      }
    ],
    []
  );
  
  const data = useMemo(() =>
    [
      { name: 'Mike', age: 20, status: 'Active', role: 'Admin', links: { profile: 'abc', category: 'def'}},
      { name: 'John', age: 30, status: 'Inactive', role: 'User', links: {  category: 'def'}},
      { name: 'Jane', age: 10, status: 'Active', role: 'User', links: { profile: 'abc'}},
      { name: 'Joe', age: 11, status: 'Inactive', role: 'User', links: { profile: 'abc', category: 'def'}},
      { name: 'Josh', age: 32, status: 'Active', role: 'User'},
      { name: 'Mark', age: 35, status: 'Active', role: 'Admin', links: { profile: 'abc', category: 'def'}},
      { name: 'Mary', age: 40, status: 'Inactive', role: 'User'},
      { name: 'Margaret', age: 50, status: 'Active', role: 'User', links: { profile: 'abc', category: 'def'}},
      { name: 'Maria', age: 60, status: 'Active', role: 'User'},
      { name: 'Jenny', age: 25, status: 'Inactive', role: 'Admin'},
      { name: 'Sam', age: 61, status: 'Active', role: 'User'},
      { name: 'Samuel', age: 31, status: 'Active', role: 'User'},
      { name: 'Sammy', age: 31, status: 'Inactive', role: 'Admin'},
      { name: 'Jake', age: 31, status: 'Active', role: 'User'},
    ],
    []
  );
    
  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    prepareRow, 
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount, 
    gotoPage,
    nextPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter }
  } = useTable(
    {
      columns, 
      data,
      initialState: { pageIndex: 0, pageSize: 5}
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  React.useEffect(() => {
  }, [globalFilter]);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  }
  
  const handleChangeRowsPerPage = event => {
    setPageSize(Number(event.target.value));
  }

  return (
    <>
      <TableContainer sx={{margin: "1px"}}>
        {
          enableFilter && 
            <div style={{marginTop: 10, height: '50px'}}>
              <input style={{width: '200px'}} type="text" placeholder="Search" value={globalFilter || ""} 
                onChange={e => setGlobalFilter(e.target.value)}/>
              <button type="submit"><FaSearch /></button>
            </div>
        }
        <MuiTable size='small' {...getTableProps()}>
          <TableHead>
            {
              headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {
                    headerGroup.headers.map(column => {
                      const {title} = column.getSortByToggleProps();
                      return (
                        <TableCell {...column.getHeaderProps(column.getSortByToggleProps())}>                            
                          {  
                            title ? 
                              <TableSortLabel 
                                active={column.isSorted}
                                // react-table has a unsorted state which is not treated here
                                direction={column.isSortedDesc ? 'desc' : 'asc'}
                              > 
                                { column.render('Header')} 
                              </TableSortLabel>
                            :
                            column.render('Header')
                          }
                        </TableCell>
                      )
                    })
                  }
                </TableRow>
              ))
            }
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {
              page.map((row, i) => {
                prepareRow(row)

                return (
                  <TableRow hover={true} {...row.getRowProps()}>
                    {
                      row.cells.map(cell => {
                        return (
                          <TableCell {...cell.getCellProps()}>
                            {
                              cell.render('Cell')
                            }
                          </TableCell>
                        )
                      })
                    }
                  </TableRow>
                )
              })
            }
          </TableBody>
          <TableFooter>
            <TableRow sx={{justifyContent: "flex-start"}}>
              {
                enablePagination && <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length}]}
                  count={data.length}
                  rowsPerPage={pageSize}
                  page={pageIndex}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton={true}
                  showLastButton={true}
                />
              }
            </TableRow>
          </TableFooter>
        </MuiTable>
      </TableContainer>
    </>
  )
};

Table.defaultProps = {
  enableFilter: true,
  enablePagination: true,
};

Table.propTypes = {
  enableFilter: PropTypes.bool,
  enablePagination: PropTypes.bool,
};

export default Table
