import React from 'react';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Copyright = props => {
  const { linkText, linkUrl, align, variant, underline, ...rest } = props;

  return (
    <Typography variant={variant} align={align}>
      {'Copyright © '}
      <Link color="inherit" href={linkUrl} variant={variant} underline={underline}>
        {linkText}
      </Link>{' '}
      { new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};

Copyright.defaultProps = {
  align: 'center',
  variant: 'body2',
  underline: 'none',
};

Copyright.propTypes = {
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  variant: PropTypes.oneOf(['body1', 'body2', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  underline: PropTypes.oneOf(['none', 'hover', 'always']),
  color: PropTypes.string,
};

export default Copyright;